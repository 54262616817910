.App {
}

Header {
	margin: 2em auto;
}

.cat-list {
	margin: 2em auto;
}

.btn.btn-primary a, .btn.btn-success a, .btn:hover a {
	color:white;
}
